<template functional>
  <div class="Steps__step Steps__step--noPadBottom">
    <template v-if="parent.step === 1">
      <div class="Steps__header">
        <h2 class="Steps__title">{{ parent.$t('tireSearch.Step1Header') }}</h2>
        <p>{{ parent.$t('tireSearch.Step1Txt') }}</p>
      </div>
    </template>
    <template v-else>
      <div class="Steps__header">
        <h2 class="Steps__title">{{ parent.$t('tireSearch.Step2Txt') }}</h2>
      </div>
    </template>
    <div class="Steps__sections" v-if="parent.step === 1">
      <div class="Steps__section">
        <div class="Steps__section">
          <car-search
            lookup=""
            :destination="parent.settings.legacy.urlDaekGuide"
            :start-node="parent.settings.legacy.nodeBil"
            season=""
            group-slug=""
            group=""
            redirect-filter=""
            wrapper-css-class="is-inline CarSearch--background no-margin"
            headline-css-class=""
            headline=""
            :car-url="parent.settings.legacy.urlCar"
            :show-manuel="true"
            :is-compact="false"
            :hide-on-desktop="false"
            :show-usp="false"
          />
        </div>
      </div>

      <div class="Steps__section">
        <tire-search
          :router="false"
          load-on="mounted"
          :destination="`/daek-guide.asp?n=${parent.settings.legacy.nodeBildaek}`"
          selected-season=""
          default-season="2"
          vehicle-id=""
          :extended-search="false"
        />
      </div>
    </div>

    <div class="Steps__sections" v-if="parent.step === 2">
      <div class="Steps__section">
        <div class="js-app-wrapper">
          <car-search
            lookup=""
            :destination="parent.settings.legacy.urlWheelGuide"
            :start-node="parent.settings.legacy.nodeBil"
            season=""
            group-slug=""
            group=""
            redirect-filter=""
            wrapper-css-class="is-inline CarSearch--background no-margin"
            headline-css-class=""
            headline=""
            :car-url="parent.settings.legacy.urlCar"
            :show-manuel="true"
            :is-compact="false"
            :hide-on-desktop="false"
            :show-usp="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sections',
  functional: true,
};
</script>
