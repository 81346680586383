<template>
  <div class="Steps">
    <template>
      <div class="Steps__prevControl" v-if="step > 0">
        <button class="Button Button--light Button--small" @click="goTo(0)">
          <i class="far fa-angle-left"></i> {{ $t('tireSearch.Tilbage') }}
        </button>
      </div>

      <div v-if="step === 0">
        <div class="Steps__step">
          <div class="Steps__header">
            <h2 class="Steps__title">{{ $t('tireSearch.HvadSoegerDu') }}</h2>
          </div>
          <div class="OptionsGrid">
            <div class="OptionCard">
              <div class="OptionCard__title" @click="goTo(1)">{{ $t('tireSearch.Daek') }}</div>
              <div class="OptionCard__content" @click="goTo(1)">
                <div class="OptionCard__preview">
                  <img src="//cdn.thg.dk/dat/dom/img/icon-carsearch-tires_2.png" />
                </div>
              </div>
              <div class="OptionCard__buttons">
                <button class="Button Button--full" @click="goTo(1)">
                  {{ $t('tireSearch.Vaelg') }}
                </button>
              </div>
            </div>

            <div class="OptionCard">
              <div class="OptionCard__title" @click="goTo(2)">
                {{ $t('tireSearch.daekOgFaelge') }}
              </div>
              <div class="OptionCard__content" @click="goTo(2)">
                <div class="OptionCard__preview">
                  <img src="//cdn.thg.dk/dat/dom/img/icon-carsearch-tires-and-wheels_2.png" />
                </div>
              </div>
              <div class="OptionCard__buttons">
                <button class="Button Button--full" @click="goTo(2)">
                  {{ $t('tireSearch.Vaelg') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="step > 0">
        <Sections />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Cookies from 'js-cookie';
import { gsap } from 'gsap/all';

import Sections from './components/Sections';
import { Message } from '@components/UI';

import settings from '@settings';

export default {
  components: {
    Message,
    Sections,
  },

  props: {
    startAt: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      working: false,
      step: this.startAt,
      settings,
    };
  },

  watch: {
    step() {
      gsap.to(window, { duration: 0.3, scrollTo: { y: this.$el, offsetY: 80 } });
    },
  },

  methods: {
    next() {
      this.step += 1;
    },
    prev() {
      this.step += 1;
    },
    goTo(step) {
      this.step = step;
    },
  },
};
</script>
